












import { Component, Vue } from 'vue-property-decorator'

@Component
export default class DropdownItem extends Vue {
  onClick (e: Event) {
    this.$emit('click', e)
  }
}
