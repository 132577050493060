























































import { Component, Prop, Vue } from 'vue-property-decorator'
import draggable from 'vuedraggable'

@Component({
  components:{ 
    draggable
  }
})
export default class DraggableContainer extends Vue {
  @Prop({default: () => ([])})
  value!: Array<any>;

  @Prop({default: 'items'})
  group!: string;

  @Prop({ default: false, type: Boolean })
  hideHint!: boolean;

  get rows(){ 
    return this.value;
  }

  set rows(data: Array<any>) { 
    this.$emit('input', data);
  }

  drag = false;
}
